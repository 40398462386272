import { ISystemMessage } from '~@Types/components/ISystemMessage'
import { cva } from 'class-variance-authority'
import { cn } from '~@Utils/shadcnHelper'
import {
  XCircle,
  CheckCircle,
  WarningCircle,
  Info,
} from '@phosphor-icons/react'

const SystemMessage = ({ className, status, desc }: ISystemMessage) => {
  const modifiers = cva(
    'typeface-body1-standard flex items-center gap-sm typeface-body-1 bg-white-white rounded-xl shadow-[rgba(0,0,0,0)_0px_0px_0px_0px,rgba(0,0,0,0)_0px_0px_0px_0px,rgba(0,0,0,0.2)_0px_0px_10px_0px] box-border border-grey-dark border-l-[4px] py-lg px-lg',
    {
      variants: {
        status: {
          error: 'border-system-danger text-system-danger',
          warning: 'border-system-warning text-system-warning',
          info: 'border-system-info text-system-info',
          success: 'border-system-success text-system-success',
        },
      },
      defaultVariants: {
        status: null,
      },
    }
  )

  const ariaLive = () => {
    if (status === 'error' || status === 'success' || status === 'warning') {
      return 'assertive'
    }

    if (status === 'info') {
      return 'polite'
    }
  }

  const role = () => {
    if (status === 'error' || status === 'success' || status === 'warning') {
      return 'alert'
    }

    if (status === 'info') {
      return 'status'
    }
  }

  const icon = () => {
    switch (status) {
      case 'error':
        return <XCircle size={28} />
        break
      case 'success':
        return <CheckCircle size={28} />
        break
      case 'warning':
        return <WarningCircle size={28} />
        break
      case 'info':
        return <Info size={28} />
        break
      default:
        return ''
        break
    }
  }

  return (
    <div
      className="SystemMessage mb-3xl container"
      role={role()}
      aria-live={ariaLive()}
    >
      <div className={cn(modifiers({ status }), className)}>
        {icon()}
        {desc && (
          <div
            className="text-black leading-normal"
            dangerouslySetInnerHTML={{ __html: desc }}
          />
        )}
      </div>
    </div>
  )
}

export default SystemMessage
