'use client'

/**
 * Contact Form Component
 *
 * A comprehensive form component that handles contact submissions with various fields
 * and validation. Supports different user profiles (professional, candidate, etc.)
 * and file uploads.
 *
 * Features:
 * - Multiple profile types with conditional fields
 * - File upload support for documents and CVs
 * - Form validation with error handling
 * - reCAPTCHA integration
 * - Responsive layout
 * - Internationalization support
 * - Google Tag Manager tracking
 *
 * @component
 * @param {Object} props
 * @param {Object} props.social - Social media links configuration
 * @param {string} props.intro - Introductory text
 * @param {Object} props.labels - Form field labels
 * @param {string} props.optional - Text to show for optional fields
 * @param {string} props.language - Current language (en/fr)
 * @param {Object} props.messages - Error and validation messages
 * @param {string} props.submitLabel - Submit button text
 * @param {Object} props.legends - Fieldset legend texts
 * @param {Object} props.role - Role selection options
 * @param {Object} props.file - File upload configuration
 * @param {Object} props.notification - Success notification config
 * @param {number} props.maxSize - Maximum file size in MB
 */

// React and Next.js imports
import { useState, useEffect, useRef } from 'react'
import { useSearchParams } from 'next/navigation'
import { useForm } from 'react-hook-form'

// Third party imports
import countries from 'world-countries'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import fr from 'react-phone-input-2/lang/fr.json'
import { Warning } from '@phosphor-icons/react'

// Atom components
import ButtonPrimary from '~@Atoms/button-primary/button-primary'
import SiteLoader from '~@Atoms/site-loader/site-loader'

// Molecule components
import SystemMessage from '~@Molecules/system-message/SystemMessage'
import NavSocial from '~@Molecules/nav-social/nav-social'
import InputFile from '~@Molecules/input-file/input-file'
import BlockMessage from '~@Molecules/block-message/block-message'

// Types and interfaces
import { IContactForm } from '~@Types/components/IContactForm'
import { IContactFormData } from '~@Types/components/IContactFormData'
import { ActionResult } from '~@Types/api/TMail'
import { ProfileType } from '~@Enum/ProfileType'

// Hooks and utilities
import { useGTM } from '~@Hooks/useGTM'
import { scrollToTop } from '@/helpers/scrollToTop'
import { PostContactAction } from './actions'
import { formatError } from '@/helpers/formatError'

// Declare grecaptcha for reCAPTCHA integration
declare const grecaptcha: any

export default function ContactForm({
  social,
  intro,
  labels,
  optional,
  language,
  messages,
  submitLabel,
  legends,
  role,
  file,
  notification,
  maxSize,
}: IContactForm) {
  // Form handling hooks and state
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IContactFormData>()
  const searchParams = useSearchParams()
  const profilParam = searchParams.get('profile')
  const [isLoading, setIsLoading] = useState(false)
  const [focusedFields, setFocusedFields] = useState({})
  const [showFieldset, setShowFieldset] = useState<string | null>(null)
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const [termsChecked, setTermsChecked] = useState(false)
  const labelRefs = useRef<{ [key: string]: HTMLLabelElement | null }>({})
  const [formError, setFormError] = useState<string | null>(null)

  // Language configuration for country names
  const localeLang = {
    en: 'eng',
    fr: 'fra',
  }
  const codeLang = localeLang[language] || 'eng'

  // Sort countries by localized name
  const sortedCountries = countries
    .map((country) => ({
      code: country.cca2,
      name: country.translations[codeLang]?.common || country.name.common,
    }))
    .sort((a, b) => a.name.localeCompare(b.name))

  // Success message configuration
  const blockMessageCfg = {
    title: notification?.title,
    desc: notification?.desc,
    btn: {
      label: notification?.btnLabel,
      link: {
        tagElt: 'Link',
        href: '/',
      },
    },
  }

  /**
   * Handles showing/hiding fieldsets based on profile selection
   */
  const handleShowInput = (event) => {
    if (event.target) {
      setShowFieldset(event.target.id)
    }
  }

  /**
   * Validates file size against maximum allowed size
   */
  const validateFileSize = (maxSizeInMB: number) => {
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024
    return (value: FileList | null) =>
      !value?.[0] || value[0].size <= maxSizeInBytes || file?.maxSize
  }

  // Form field configuration
  const fields: any = [
    {
      legend: legends?.role,
      inputs: [
        {
          profile: {
            type: 'radio',
            fct: handleShowInput,
            required: messages?.requiredField,
            inputs: [
              {
                label: role?.professional,
                current: profilParam === 'professional',
              },
              { label: role?.candidate, current: profilParam === 'candidate' },
              { label: role?.investor, current: profilParam === 'investor' },
              {
                label: role?.journalist,
                current: profilParam === 'journalist',
              },
              { label: role?.other, current: profilParam === 'other' },
            ],
          },
        },
      ],
    },
    {
      legend: legends?.request,
      inputs: [
        {
          lastname: {
            label: labels?.lastname,
            type: 'text',
            required: messages?.requiredField,
          },
          firstname: {
            label: labels?.firstname,
            type: 'text',
            required: messages?.requiredField,
          },
        },
        {
          company: {
            referenceId: 'profile-0',
            label: labels?.company,
            type: 'text',
          },
        },
        {
          job: {
            referenceId: 'profile-1',
            label: labels?.job,
            type: 'text',
          },
        },
        {
          location: {
            label: labels?.location,
            type: 'select',
            width: 'half',
            options: sortedCountries.map((country) => ({
              value: country.code,
              label: country.name,
            })),
          },
        },
        {
          phone: {
            label: labels?.phone,
            type: 'tel',
            width: 'half',
          },
        },
        {
          email: {
            label: labels?.email,
            type: 'email',
            required: messages?.requiredField,
            pattern: {
              value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
              message: messages?.errorEmail,
            },
          },
        },
        {
          message: {
            label: labels?.message,
            type: 'textarea',
            required: messages?.requiredField,
          },
        },
      ],
    },
    {
      legend: legends?.doc,
      referenceId: 'profile-0',
      inputs: [
        {
          document: {
            label: file?.label,
            desc: file?.description,
            type: 'file',
            name: 'document',
            format: '.doc, .docx, .pdf',
            validate: validateFileSize(maxSize),
          },
        },
      ],
    },
    {
      legend: legends?.cv,
      referenceId: 'profile-1',
      inputs: [
        {
          cv: {
            label: file?.label,
            desc: file?.description,
            type: 'file',
            name: 'cv',
            format: '.doc, .docx, .pdf',
            required: messages?.requiredField,
            validate: validateFileSize(maxSize),
          },
        },
      ],
    },
    {
      inputs: [
        {
          terms: {
            type: 'checkbox',
            required: messages?.requiredField,
            label: labels?.terms,
          },
        },
      ],
    },
  ]

  const { trackFormSubmit } = useGTM()

  /**
   * Handles form submission
   * Validates reCAPTCHA, prepares form data, and submits to API
   */
  const onSubmit = async (data: IContactFormData) => {
    try {
      setIsLoading(true)
      setFormError(null)

      // Execute reCAPTCHA and get the token
      const token = await grecaptcha.execute(
        process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY,
        { action: 'submit' }
      )

      // Append the token to the form data
      const serializableData = prepareFormData(data, showFieldset)
      const formData = createFormData(serializableData)
      formData.append('recaptcha_token', token)

      const actionResult: ActionResult = await PostContactAction(formData)
      if (actionResult.success) {
        setIsFormSubmitted(true)
        trackFormSubmit(serializableData?.profileType)
        scrollToTop()
        return
      }
      throw new Error(actionResult.error || 'An unexpected error occurred')
    } catch (error) {
      setFormError(
        error instanceof Error
          ? error.message
          : 'An error occurred while submitting the form'
      )
      scrollToTop()
      formatError(error, 'ContactForm')
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * Handles input focus state
   */
  const handleFocus = (fieldName) => {
    setFocusedFields((prev) => ({ ...prev, [fieldName]: true }))
  }

  /**
   * Handles input blur state
   */
  const handleBlur = (fieldName, value) => {
    setFocusedFields((prev) => ({
      ...prev,
      [fieldName]: value.trim() !== '',
    }))
  }

  /**
   * Gets label positioning class based on field state
   */
  const getLabelClass = (fieldName, error, type) => {
    let labelUp = 'top-none'
    let labelDown = 'top-1/2'
    if (type === 'textarea') {
      labelUp = '-top-sm'
      labelDown = 'top-md'
    }
    if (focusedFields[fieldName] || error) {
      return labelUp
    }
    return labelDown
  }

  /**
   * Error message component
   */
  const ErrorMessage = ({ name, errors }) => {
    if (!errors[name]) return null
    return (
      <p
        aria-describedby={name}
        className="my-xs text-system-danger typeface-caption-special flex items-center"
      >
        <Warning size={16} className="mr-xs" /> {errors[name].message}
      </p>
    )
  }

  /**
   * Gets input class names based on error state
   */
  const getInputClass = (hasError) =>
    `bg-white-white typeface-subtitle2-standard block border-xs p-md rounded-xl w-full focus:outline-black-black focus:outline-2 focus:outline-offset-2 ${
      hasError
        ? 'text-system-danger/50 border-system-danger'
        : 'text-black-60 border-grey-semi'
    }`

  // Set initial fieldset based on URL parameters
  useEffect(() => {
    fields.some((fieldGroup) => {
      if (!Array.isArray(fieldGroup.inputs)) return false
      return fieldGroup.inputs.some((inputGroup) => {
        return Object.entries(inputGroup).some(([key, value]) => {
          const inputValue = value as { inputs?: { current?: boolean }[] }
          const indexCurrent = inputValue.inputs?.findIndex(
            (input) => input?.current
          )
          if (indexCurrent !== -1) {
            setShowFieldset(`${key}-${indexCurrent}`)
            return true
          }
          return false
        })
      })
    })
  }, [])

  if (isLoading) {
    return <SiteLoader />
  }

  return (
    <>
      <form className="container my-large" onSubmit={handleSubmit(onSubmit)}>
        {(Object.keys(errors).length > 0 || formError) && (
          <SystemMessage
            desc={formError || (messages ? messages.errorForm : '')}
            status="error"
          />
        )}
        <div className="desktop:grid desktop:grid-cols-12 desktop:gap-3xl">
          {intro && (
            <div className="desktop:col-start-1 desktop:col-end-5 mb-lg desktop:mb-none">
              <p className="typeface-body1-standard text-brand-primary">
                {intro}
              </p>
              {social && (
                <NavSocial
                  className="mt-xl"
                  isHorizontal={true}
                  isAlternativeVersion={true}
                  {...social}
                />
              )}
            </div>
          )}
          <div className="desktop:col-start-6 desktop:col-end-13">
            {!isFormSubmitted &&
              fields
                .filter(
                  (fieldGroup) =>
                    !fieldGroup.referenceId ||
                    showFieldset === fieldGroup.referenceId
                )
                .map((fieldGroup, groupIndex) => (
                  <fieldset
                    key={groupIndex}
                    className="flex flex-col gap-y-md mb-3xl"
                  >
                    {fieldGroup.legend && (
                      <legend className="typeface-overline2-standard uppercase mb-lg">
                        {fieldGroup.legend}
                      </legend>
                    )}
                    {fieldGroup.inputs
                      .filter((inputGroup) =>
                        Object.entries(inputGroup).some(([_, options]: any) => {
                          return (
                            !options.referenceId ||
                            showFieldset === options.referenceId
                          )
                        })
                      )
                      .map((inputGroup, inputGroupIndex) => (
                        <div
                          key={inputGroupIndex}
                          className={
                            Object.entries(inputGroup).length === 2
                              ? 'gap-md grid desktop:grid-cols-2'
                              : ''
                          }
                        >
                          {(
                            Object.entries(inputGroup) as [
                              string,
                              Record<string, any>,
                            ][]
                          )
                            .filter(([_, options]) => {
                              return (
                                !options.referenceId ||
                                showFieldset === options.referenceId
                              )
                            })
                            .map(([name, options]) => {
                              let inputElement
                              switch (options.type) {
                                case 'textarea':
                                  inputElement = (
                                    <textarea
                                      className={getInputClass(!!errors[name])}
                                      id={name}
                                      aria-required={
                                        options?.required ? 'true' : 'false'
                                      }
                                      rows={6}
                                      {...register(
                                        name as keyof IContactFormData,
                                        options
                                      )}
                                      onFocus={() => handleFocus(name)}
                                      onBlur={(e) =>
                                        handleBlur(name, e.target.value)
                                      }
                                    />
                                  )
                                  break
                                case 'select':
                                  inputElement = (
                                    <select
                                      className={getInputClass(!!errors[name])}
                                      id={name}
                                      {...register(
                                        name as keyof IContactFormData,
                                        {
                                          required: options?.required,
                                        }
                                      )}
                                      name={name}
                                      onFocus={() => handleFocus(name)}
                                      onBlur={(e) =>
                                        handleBlur(name, e.target.value)
                                      }
                                    >
                                      <option value="">...</option>
                                      {options.options &&
                                        options.options.map((option) => (
                                          <option
                                            key={option.value}
                                            value={option.label}
                                          >
                                            {option.label}
                                          </option>
                                        ))}
                                    </select>
                                  )
                                  break
                                case 'tel':
                                  inputElement = (
                                    <PhoneInput
                                      country={'fr'}
                                      localization={
                                        language === 'fr' ? fr : undefined
                                      }
                                      inputProps={{
                                        name: name,
                                        id: name,
                                      }}
                                      inputClass="bg-white-white !w-full !typeface-subtitle2-standard block !border-xs !px-md !pr-md !pl-[52px] !rounded-xl !text-black-60 !border-grey-semi focus:!border-black-black focus:!outline-2 focus:!outline-offset-2 focus:ring-black-black focus:!ring-1"
                                      onChange={(value) =>
                                        setValue('phone', value)
                                      }
                                      onFocus={() => handleFocus(name)}
                                      onBlur={(e) =>
                                        handleBlur(name, e.target.value)
                                      }
                                      specialLabel=""
                                      placeholder=""
                                      value=""
                                    />
                                  )
                                  break
                                case 'file':
                                  inputElement = (
                                    <InputFile
                                      label={options.label}
                                      name={name}
                                      format={options.format}
                                      desc={options.desc}
                                      required={
                                        errors[name]?.message ? true : false
                                      }
                                      optionalText={
                                        !options.required ? optional : ''
                                      }
                                      register={register(
                                        name as keyof IContactFormData,
                                        {
                                          required: options.required,
                                          validate: options.validate,
                                        }
                                      )}
                                    />
                                  )
                                  break
                                case 'radio':
                                  inputElement = (
                                    <div className="flex flex-wrap gap-xs">
                                      {options.inputs?.map((input, index) => {
                                        const inputId = `${name}-${index}`
                                        return (
                                          <div key={inputId}>
                                            <input
                                              type={options.type}
                                              id={inputId}
                                              onClick={options.fct}
                                              className="sr-only peer"
                                              {...register(
                                                name as keyof IContactFormData,
                                                {
                                                  required: options.required,
                                                }
                                              )}
                                              value={input.label}
                                              defaultChecked={
                                                input.current || false
                                              }
                                            />
                                            <label
                                              className={`${errors[name] ? 'text-system-danger border-system-danger' : ''} typeface-subtitle2-standard block px-sm py-xs text-black-black bg-white-white rounded-full border-black-black border-[1px] transition-all peer-checked:bg-black-black peer-checked:text-white-white hover:bg-grey-light cursor-pointer peer-focus:ring-black-black peer-focus:ring-1`}
                                              htmlFor={inputId}
                                            >
                                              {input.label}
                                            </label>
                                          </div>
                                        )
                                      })}
                                    </div>
                                  )
                                  break
                                case 'checkbox':
                                  inputElement = (
                                    <div className="flex items-baseline">
                                      <input
                                        type={options.type}
                                        id={name}
                                        {...register(
                                          name as keyof IContactFormData,
                                          {
                                            required: options.required,
                                          }
                                        )}
                                        className="focus:outline-black-black focus:outline-2 focus:outline-offset-2"
                                        checked={termsChecked}
                                        onChange={(e) => {
                                          const isChecked = e.target.checked
                                          setTermsChecked(isChecked)
                                          isChecked
                                            ? delete errors[name]
                                            : (errors[name] = {
                                                message: options.required,
                                              })
                                        }}
                                      />
                                      <label
                                        className={`${errors[name] ? 'text-system-danger border-system-danger' : ''} typeface-subtitle2-standard px-sm text-black-black`}
                                        htmlFor={name}
                                      >
                                        {options.label}
                                      </label>
                                    </div>
                                  )
                                  break
                                default:
                                  inputElement = (
                                    <input
                                      className={getInputClass(!!errors[name])}
                                      id={name}
                                      aria-required={
                                        options?.required ? 'true' : 'false'
                                      }
                                      type={options.type}
                                      aria-describedby={name}
                                      {...register(
                                        name as keyof IContactFormData,
                                        options
                                      )}
                                      onFocus={() => handleFocus(name)}
                                      onBlur={(e) =>
                                        handleBlur(name, e.target.value)
                                      }
                                    />
                                  )
                                  break
                              }
                              return (
                                inputElement && (
                                  <div key={name}>
                                    <div
                                      className={`relative ${options.width === 'half' ? 'gap-md grid desktop:grid-cols-2' : ''}`}
                                    >
                                      {options.type !== 'file' &&
                                        options.type !== 'radio' &&
                                        options.type !== 'checkbox' && (
                                          <label
                                            className={`absolute z-[1] transition-all duration-250
                                        ${getLabelClass(name, errors[name], options.type)}
                                        ${
                                          options.type === 'textarea'
                                            ? ''
                                            : 'top-1/2 -translate-y-1/2'
                                        } typeface-subtitle2-standard transform w-full flex gap-2xs items-center pl-xs pr-md border-box ${
                                          errors[name]
                                            ? 'text-system-danger'
                                            : ''
                                        }`}
                                            ref={(el) => {
                                              labelRefs.current[name] = el
                                            }}
                                            htmlFor={name}
                                          >
                                            <span className="bg-white-white px-xs">
                                              {options.label}{' '}
                                              {!options.required && (
                                                <span className="text-grey-grey">
                                                  ({optional})
                                                </span>
                                              )}
                                            </span>
                                          </label>
                                        )}
                                      {inputElement}
                                    </div>
                                    {errors[name] && (
                                      <ErrorMessage
                                        name={name}
                                        errors={errors}
                                      />
                                    )}
                                  </div>
                                )
                              )
                            })}
                        </div>
                      ))}
                  </fieldset>
                ))}
            {!isFormSubmitted && (
              <ButtonPrimary
                label={submitLabel}
                link={{
                  tagElt: 'button',
                  type: 'submit',
                }}
              />
            )}
            {isFormSubmitted && <BlockMessage {...blockMessageCfg} />}
          </div>
        </div>
      </form>
    </>
  )
}

/**
 * Prepares form data for submission by cleaning up unnecessary fields
 * based on selected profile type
 */
const prepareFormData = (
  data: IContactFormData,
  showFieldset: string | null
) => {
  const serializableData = {
    ...data,
    profileType: getProfileType(showFieldset),
  }
  if (showFieldset !== 'profile-0') {
    delete serializableData.document
    delete serializableData.company
  }
  if (showFieldset !== 'profile-1') {
    delete serializableData.cv
    delete serializableData.job
  }
  return serializableData
}

/**
 * Maps profile fieldset IDs to ProfileType enum values
 */
const getProfileType = (showFieldset: string | null): string => {
  const profileMapping = {
    'profile-0': ProfileType.PROFESSIONAL,
    'profile-1': ProfileType.CANDIDATE,
    'profile-2': ProfileType.INVESTOR,
    'profile-3': ProfileType.JOURNALIST,
    'profile-4': ProfileType.OTHER,
  }
  return profileMapping[showFieldset || ''] || ''
}

/**
 * Creates FormData object for submission, handling both JSON data and file uploads
 */
const createFormData = (data: any): FormData => {
  const formData = new FormData()
  try {
    // Create a clean data object without File objects
    const cleanData = { ...data }
    // Remove file objects from cleanData before stringifying
    delete cleanData.document
    delete cleanData.cv
    // Add the JSON data first
    formData.append('data', JSON.stringify(cleanData))
    // Add files separately with metadata
    if (data.document?.[0] instanceof File) {
      const file = data.document[0]
      formData.append('files.document', file)
      formData.append(
        'documentMetadata',
        JSON.stringify({
          name: file.name,
          size: file.size,
          type: file.type,
        })
      )
    }
    if (data.cv?.[0] instanceof File) {
      const file = data.cv[0]
      formData.append('files.cv', file)
      formData.append(
        'cvMetadata',
        JSON.stringify({
          name: file.name,
          size: file.size,
          type: file.type,
        })
      )
    }
    return formData
  } catch (error) {
    formatError(error, 'ContactForm')
    throw new Error('>>>>> Error while creating the form')
  }
}
