'use client'

import { useEffect } from 'react'

declare global {
  interface Window {
    grecaptcha?: any
  }
}

export default function RecaptchaScript() {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}`
    script.id = 'recaptcha-script'
    document.head.appendChild(script)

    return () => {
      const scriptElement = document.getElementById('recaptcha-script')
      if (scriptElement) {
        document.head.removeChild(scriptElement)
      }

      const recaptchaElements =
        document.getElementsByClassName('grecaptcha-badge')
      while (recaptchaElements.length > 0) {
        recaptchaElements[0].remove()
      }

      if (window.grecaptcha) {
        delete window.grecaptcha
      }
    }
  }, [])

  return null
}
