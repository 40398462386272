'use client'

import { useState } from 'react'
import { Plus, CloudArrowUp } from '@phosphor-icons/react'
import { IInputFile } from '~@Types/components/IInputFile'

const InputFile = ({
  label,
  desc,
  name,
  format,
  required,
  optionalText,
  register,
}: IInputFile) => {
  const [fileName, setFileName] = useState<string | null>(null)

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setFileName(event.target.files[0].name)
    } else {
      setFileName(null)
    }
  }

  return (
    <div
      className={`InputFile typeface-subtitle2-standard text-center p-lg rounded-xs border-[1px] ${
        required
          ? 'text-system-danger border-system-danger'
          : 'text-black-black border-grey-grey'
      } border-dashed`}
    >
      <CloudArrowUp size={32} className="mx-auto" />
      {label && (
        <label className="mt-xs block" htmlFor={name}>
          {label}{' '}
          {optionalText && (
            <span className="text-grey-grey">({optionalText})</span>
          )}
        </label>
      )}
      {desc && <p className="text-grey-dark mt-xs">{desc}</p>}
      {name && (
        <div className="relative typeface-body2-standard inline-flex items-center mt-md bg-brand-secondary rounded-full overflow-hidden text-white-white transition-all hover:bg-black-black">
          <Plus size={20} className="w-full h-full px-md py-xs" />
          <input
            type="file"
            id={name}
            name={name}
            accept={format || '.doc, .pdf'}
            className="absolute opacity-0 top-none left-none w-full h-full"
            onChange={(e) => {
              handleFileChange(e)
              register.onChange(e)
            }}
            ref={register.ref}
            onBlur={register.onBlur}
          />
          {fileName && (
            <span className="pr-md whitespace-nowrap">{fileName}</span>
          )}
        </div>
      )}
    </div>
  )
}

export default InputFile
