import ButtonPrimary from '~@Components/atoms/button-primary/button-primary'
import { IBlockMessage } from '~@Types/components/IBlockMessage'

const BlockMessage = ({ title, desc, btn }: IBlockMessage) => {
  return (
    <>
      {(title || desc) && (
        <div className="BlockMessage p-xl rounded-lg bg-grey-x-light typeface-body1-standard text-center">
          {title && <p className="typeface-body1-special">{title}</p>}
          {desc && <p>{desc}</p>}
          {btn && <ButtonPrimary className="mt-2xl" {...btn} />}
        </div>
      )}
    </>
  )
}

export default BlockMessage
